import React from "react"
import { graphql } from "gatsby"
import addToMailchimp from "gatsby-plugin-mailchimp"
import * as Yup from "yup"
import { Formik, Field } from "formik"
import ReactMarkdown from "react-markdown"
import SnackbarProvider, { useSnackbar } from "react-simple-snackbar"

import {
  BlogArticleFeatured,
  BlogArticleStandard,
  Form,
  Layout,
  Seo,
  TagFilterButton,
  SnackBarOptions,
} from "../components/Shared"
import { CoverYourHome } from "../components/Sections"

const Blog = ({ data }) => {
  const notFeaturedArticles = data.notFeaturedArticles.edges.filter(
    ({ node }) => node.contentful_id !== "7zopwST3KykcVORiCAo4y1"
  )
  const featuredArticles = data.featuredArticles.edges
  const allBlogTags = data.tags.edges

  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedFilter, setSelectedFilter] = React.useState("allArticles")
  const [filteredArticles, setFilteredArticles] = React.useState(
    notFeaturedArticles
  )

  React.useEffect(() => {
    setIsLoading(true)
    updatedFilteredArticles()
  }, [selectedFilter])

  const updatedFilteredArticles = async () => {
    if (selectedFilter === "allArticles") {
      setFilteredArticles(notFeaturedArticles)
    } else {
      setFilteredArticles(
        notFeaturedArticles.filter(({ node }) =>
          node.tags.find((tag) => tag.id === selectedFilter)
        )
      )
    }
    setIsLoading(false)
  }

  return (
    <SnackbarProvider>
      <Layout>
        <Seo
          url={data.site && data.site.siteMetadata.url + "/blog"}
          title={data.headerAndSeo.seo && data.headerAndSeo.seo.title}
          description={data.headerAndSeo.seo.metaDescription.metaDescription}
          image={
            data.headerAndSeo.seo &&
            data.headerAndSeo.seo.image &&
            data.headerAndSeo.seo.image.file &&
            data.headerAndSeo.seo.image.file.url
          }
        />
        <header className="wrapper-width header blog-header">
          <MailchimpSignUpForm
            header={data.headerAndSeo.headerTitle}
            paragraph={data.headerAndSeo.headerParagraph.headerParagraph}
          />
        </header>
        <main>
          {featuredArticles.length > 0 && (
            <section className="background-grey-lightest">
              <div className="wrapper-width wrapper-height featured-articles">
                <h2 className="heading-medium">Featured articles</h2>
                {featuredArticles.map(({ node }) => (
                  <BlogArticleFeatured
                    key={node.contentful_id}
                    title={node.title}
                    featuredText={node.featuredText.featuredText}
                    featuredImage={node.featuredImage.fluid}
                    featuredImageDescription={node.featuredImage.title}
                    tags={node.tags}
                    author={node.author}
                    slug={node.slug}
                  />
                ))}
              </div>
            </section>
          )}
          {notFeaturedArticles.length > 0 && (
            <section
              className={
                featuredArticles.length === 0 ? "background-grey-lightest" : ""
              }
            >
              <section className="wrapper-width wrapper-height">
                {allBlogTags.length > 0 && (
                  <>
                    <h2 className="heading-medium">All articles</h2>
                    <div className="tag-filter-buttons">
                      <TagFilterButton
                        tag="All articles"
                        active={selectedFilter === "allArticles"}
                        onClick={() => setSelectedFilter("allArticles")}
                      />
                      {allBlogTags.map(({ node }) => (
                        <TagFilterButton
                          key={node.id}
                          tag={node.tag}
                          active={selectedFilter === node.id}
                          onClick={() => setSelectedFilter(node.id)}
                        />
                      ))}
                    </div>
                  </>
                )}

                {filteredArticles.length > 0 ? (
                  <div className="grid-col-3">
                    {filteredArticles.map(({ node }) => (
                      <BlogArticleStandard
                        key={node.contentful_id}
                        title={node.title}
                        featuredText={node.featuredText.featuredText}
                        featuredImage={node.featuredImage.fluid}
                        featuredImageDescription={node.featuredImage.title}
                        tags={node.tags}
                        slug={node.slug}
                      />
                    ))}
                  </div>
                ) : (
                  "No articles"
                )}
              </section>
            </section>
          )}
        </main>
        <CoverYourHome backgroundColour="grey-lightest" />
      </Layout>
    </SnackbarProvider>
  )
}

const MailchimpSignUpForm = ({ header, paragraph }) => {
  const [openSnackbar] = useSnackbar(SnackBarOptions)

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await addToMailchimp(values.email)
      setSubmitting(false)
      resetForm({})
      openSnackbar(
        <div
          className="snackbar-inner"
          dangerouslySetInnerHTML={{ __html: response.msg }}
        ></div>
      )
    } catch (error) {
      openSnackbar()
    }
  }

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email address")
          .required("Required"),
      })}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form className="signOffMailingList">
          <h1 className="heading-extra-large">{header}</h1>
          <ReactMarkdown source={paragraph} />
          <div>
            <div className="email-signup">
              <Field
                className="input-field"
                name="email"
                type="email"
                placeholder="Your email"
              />
              <button
                disabled={isSubmitting}
                className="button-primary button-with-loader"
                type="submit"
              >
                <span className="button-with-loader-text">Subscribe</span>
              </button>
            </div>
            <span className="form-error-text">
              {touched.email && errors.email && errors.email}
            </span>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Blog

export const pageQuery = graphql`
  query GetAllBlogArticles {
    site {
      siteMetadata {
        url
      }
    }
    notFeaturedArticles: allContentfulBlogArticles(
      filter: { featuredArticle: { eq: false } }
      sort: { order: ASC, fields: createdAt }
    ) {
      edges {
        node {
          id
          featuredImage {
            title
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          featuredText {
            id
            featuredText
          }
          featuredArticle
          contentful_id
          slug
          title
          tags {
            tag
            id
          }
        }
      }
    }
    featuredArticles: allContentfulBlogArticles(
      filter: { featuredArticle: { eq: true } }
      sort: { order: ASC, fields: createdAt }
    ) {
      edges {
        node {
          id
          featuredImage {
            title
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          featuredText {
            id
            featuredText
          }
          author {
            authorName
            authorImage {
              fluid {
                ...GatsbyContentfulFluid
              }
              title
            }
          }
          featuredArticle
          contentful_id
          slug
          title
          tags {
            tag
            id
          }
        }
      }
    }
    tags: allContentfulBlogArticleTags(sort: { order: ASC, fields: tag }) {
      edges {
        node {
          id
          tag
        }
      }
    }
    headerAndSeo: contentfulPages(page: { eq: "Blog" }) {
      contentful_id
      page
      seo {
        image {
          file {
            url
          }
        }
        metaDescription {
          metaDescription
        }
        title
      }
      headerTitle
      headerParagraph {
        headerParagraph
      }
    }
  }
`
